import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/UI/Container"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Resoute Web" />
    <Container>
      <h1>NOT FOUND</h1>
      <Link to="/">Return Home</Link>
    </Container>
  </Layout>
)

export default NotFoundPage
